import { Container, Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Logo } from "Components";
import NavLink from "./NavLink";
import SiteNavbar from "./SiteNavbar";
import { routes } from "data";

const navLinks = routes.filter((route) => route.showInNavbar);

const Header = () => {
  return (
    <SiteNavbar collapseOnSelect expand="lg" className="navigation" variant="dark">
      <Container>
        <Logo />
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto" />
          <Nav>
            {navLinks.map(({ path, label }, index) => (
              <LinkContainer key={index} to={path}>
                <NavLink eventKey={index}>{label}</NavLink>
              </LinkContainer>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </SiteNavbar>
  );
};

export default Header;
