import { Routes, Route } from "react-router-dom";
import routes from "data/routes";

const AppRoutes = () => (
  <Routes>
    {routes.map(({ path, component: RouteComponent }, index) => (
      <Route key={index} path={path} element={<RouteComponent />} />
    ))}
  </Routes>
);

export default AppRoutes;
