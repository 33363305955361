import {
  Home,
  Contacts,
  Services,
  Partnership,
  Fences,
  Stairs,
  Sheds,
  GarbageAreas,
  Gates,
  GardenProducts,
  MebelLoft,
  IndustrialProduction,
  BicycleParking,
  NotFound,
} from "Pages";

const routes = [
  { path: "/", label: "Главная", showInNavbar: true, component: Home },
  { path: "/contacts", label: "Контакты", showInNavbar: true, component: Contacts },
  { path: "/services", label: "Услуги", showInNavbar: true, component: Services },
  { path: "/partnership", label: "Cотрудничество", showInNavbar: true, component: Partnership },
  { path: "/services/fences", component: Fences },
  { path: "/services/stairs", component: Stairs },
  { path: "/services/sheds", component: Sheds },
  { path: "/services/garbage-areas", component: GarbageAreas },
  { path: "/services/gates", component: Gates },
  { path: "/services/garden-products", component: GardenProducts },
  { path: "/services/mebel-loft", component: MebelLoft },
  { path: "/services/industrial-production", component: IndustrialProduction },
  { path: "/services/bicycle-parking", component: BicycleParking },
  { path: "*", component: NotFound },
];

export default routes;
