import styled from "styled-components";
import { Device } from "Utils";
import { IconMts } from "Assets";
import ViberIcon from "../ViberIcon";
import { StyledLink } from "Components";
import { firmData } from "data";

const LogoViberIcon = styled(ViberIcon)`
  margin-left: 10px;
`;

const LogoTitle = styled.h4.attrs({
  className: "navbar-title",
})`
  font-weight: bold;
  text-transform: uppercase;

  @media (min-width: ${Device.mobile}) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (min-width: ${Device.desktop}) {
    font-size: 30px;
    line-height: 40px;
  }
`;

const LogoSubtitle = styled.span`
  font-size: 24px;
  font-family: "Caveat", Arial, cursive;
  font-weight: bold;
  color: ${(props) => props.theme.colors.white};
  line-height: 24px;

  @media (max-width: ${Device.mobile}) {
    font-size: 20px;
    line-height: 20px;
  }
`;

const ContactsContainer = styled.span`
  display: flex;
  align-items: center;
`;

const MtsImage = styled.img`
  margin-right: 5px;
`;

const Logo = () => {
  return (
    <div className="navbar-brand">
      <StyledLink color="white" href="/">
        <LogoTitle>{firmData.siteName}</LogoTitle>
      </StyledLink>

      <ContactsContainer>
        <a
          className="text-decoration-none"
          href={`tel:${firmData.phoneNumber}`}
          target="_blank"
          rel="noreferrer"
        >
          <MtsImage alt="МТС" width={26} height={26} src={IconMts} />
          <LogoSubtitle>{firmData.phoneNumberFormatted}</LogoSubtitle>
        </a>
        <LogoViberIcon />
      </ContactsContainer>
    </div>
  );
};

export default Logo;
