import { Helmet } from "react-helmet-async";

const metaTags = [
  {
    name: "description",
    content:
      "Сварочные работы любой сложности в Бресте и Брестской области. Изготовление ворот, металлических решеток, ограждений, козырьков, навесов, лестниц и др. изделий.",
  },
  {
    name: "keywords",
    content:
      "сварщик, сварщик брест, сварочные работы, сварочные работы в бресте, сварочные работы полуавтоматом, сварочные работы цена, услуги сварщика, сварочные работы цена прайс, монтажные работы, строительно-монтажные работы, токарные работы, ковка, ковка брест",
  },
];

const MetaTags = () => (
  <Helmet>
    {metaTags.map(({ name, ...props }) => (
      <meta key={name} {...props} />
    ))}
  </Helmet>
);

export default MetaTags;
