import styled from "styled-components";
import { Nav } from "react-bootstrap";
import { Device } from "Utils";

const NavLink = styled(Nav.Link)`
  font-size: 18px !important;
  color: ${({ theme }) => theme.colors.white} !important;
  text-align: center;

  &.nav-link.active {
    color: ${({ theme }) => theme.colors.primaryYellow} !important;
  }

  :hover {
    color: ${({ theme }) => theme.colors.primaryYellow} !important;
  }

  @media (hover: none), (hover: on-demand) {
    :hover {
      color: ${({ theme }) => theme.colors.white} !important;
    }
  }

  @media (min-width: ${Device.desktop}) {
    margin-right: 20px;
  }
`;

export default NavLink;
